export const state = () => ({
	menus: [],
	hoveredSubmenuIndex: null
})

export const actions = {
	async getMenubars({ commit, rootState }) {
		const theme = this.$config.THEME
		const response = await this.$axios(`cms/lnd/menubar-contents?currency=${rootState.settings.defaultCurrency}&theme_code=${theme}`, {
			retry: 5,
			retryDelay: 500
		})
			.then((data) => {
				commit("setMenubars", data?.data)
			})
			.catch(_ => {
				return false;
			})
		return response
	}
}

export const mutations = {
	setMenubars(state, menus) {
		if (!menus.length) return;
		// Sort main menu and sub menu sequence
		const sortedMenubars = menus.sort((a, b) =>
			~~a?.sequence - ~~b?.sequence
		)
		sortedMenubars.forEach(menu => {
			menu?.items.sort((a, b) => a?.sequence - b?.sequence);
		})
		state.menus = sortedMenubars
	},
	setHoveredSubmenuIndex: (state, index) => {
		state.hoveredSubmenuIndex = index
	}
}