// State
export const state = () => ({
	// loginPopup: false,
	token: null,
	loading: false,
	totalBalanceLoading: false,
	player: {
		balance: 0,
	},
	totalBalance: '',
	totalBalanceFromSocket: null,
	profile: null,
	messages: [],
	deposits: [],
	withdrawals: [],
	wallets: {},
	providerWallets: [],
	affiliates: [],
	onHoldWallet: 0,
	playerInviteAndEarnDomain: { domain: null }
})

// Actions
export const actions = {
	// Logout
	async logout({ commit, dispatch }) {
		try {
			if (this.$cookie.get('token')) {
				await this.$axios.put('/players/logout').finally(() => {
					this.$cookie.remove("token");
					dispatch('settings/toggleIsLogin', false, { root: true })
					this.$toast.success(this.getters['themes/language-string/tl']('logoutSuccessful'));
					this.$cookie.remove("player");
				})
			}
		} catch (error) {

		}
	},

	// Profile
	async profile({ commit, dispatch }) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/players/profile`, {
				})
				.then((response) => {
					commit('setPlayer', response.data)
					commit('setTotalBalance', response?.data?.balance)
					dispatch('player/totalBalanceSocket', true, { root: true })
					this.$cookie.set('player', response.data)
					commit('loading', false)
				})
				.catch(() => {
					commit('loading', false)
				})
		} catch (error) { }
	},

	// Update
	async update({ commit }, form) {
		commit('loading', true)
		await this.$axios
			.put(`/players`, form)
			.then((response) => {
				commit('loading', false)
				if (!form.language && response) {
					this.$toast.success('Player info successfully updated')
				}
			})
			.catch(() => {
				commit('loading', false)
			})
	},
	// ? GET TOTAL BALANCE
	async totalBalance({ commit }) {
		commit('totalBalanceLoading', true)

		await this.$axios
			.get(`/players/wallets/total`, {})
			.then((response) => {
				commit('setTotalBalance', response?.data?.main_wallet || response?.data?.mainWallet)
				commit('setOnHoldWallet', response?.data?.onHoldWallet)
				commit('totalBalanceLoading', false)
			})
			.catch(() => {
				commit('totalBalanceLoading', false)
			})
	},
	async affiliates({ commit }) {
		commit('loading', true)
		await this.$axios
			.get(`/reference`)
			.then((response) => {
				commit('affiliates', response.data)
				commit('loading', false)
			})
			.catch(() => {
				commit('loading', false)
			})
	},
	totalBalanceSocket({ commit }, params) {
		commit('setTotalBalanceSocket', params)
	},
	async playerWalletsTransfer({ commit }, form) {
		const response = await this.$axios
			.post(`/players/wallets/transfer`, form)
			.then((response) => {
				return true;
			})
			.catch(() => {
				return false;
			})
		return response;
	},
	setHoldBalance({ commit }, val) {
		commit('setOnHoldWallet', val)
	},
	async getPlayerInviteAndEarnDomain({ commit }, params) {
		if (!this.$cookie.get('token')) return;
		await this.$axios
			.get(`/players/invite-and-earn/share-link`)
			.then((response) => {
				commit('setPlayerInviteAndEarnDomain', response.data)
				this.$cookie.set('referral_domain', response.data?.domain, {
					path: '/',
					maxAge: 60 * 60 * 3 - 60,
				})
			})
			.catch(() => {
				return false;
			})
	}
}

// Mutations
export const mutations = {
	setPlayer: (state, data) => {
		state.player = data
	},
	loading: (state, type) => {
		state.loading = type
	},
	totalBalanceLoading: (state, type) => {
		state.totalBalanceLoading = type
	},
	setMessages(state, data) {
		state.messages = data
	},
	wallets: (state, v) => {
		state.wallets = v
	},
	setTotalBalance: (state, v) => {
		state.totalBalance = v
		state.totalBalanceFromSocket = v
	},
	setTotalBalanceSocket: (state, data) => {
		state.totalBalanceFromSocket = data.total_balance
	},
	affiliates: (state, data) => {
		state.affiliates = data
	},
	setOnHoldWallet: (state, data) => {
		state.onHoldWallet = data
	},
	setPlayerInviteAndEarnDomain: (state, data) => {
		state.playerInviteAndEarnDomain = data
	}
}