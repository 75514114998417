import { globalVar } from "~/utils/globalVar"

const retry = {
	retry: 5,
	retryDelay: 500
}

const filterAndSortArticle = (cms, componentCode) => {
	const filterAndSort = cms?.filter((object) => object.component_code === componentCode).map(obj => ({
		...obj,
		items: obj.items
			.filter(item => item.is_visible && item.is_active) // Filter by is_visible and is_active
			.sort((a, b) => a.sequence - b.sequence) // Sort by sequence
	}));
	return filterAndSort[0] || {}
}
// State
export const state = () => ({
	loading: false,
	cms: [],
	jackpotVal: -1,
	favicon: null
})

// Actions
export const actions = {
	// Get pages
	async cms({ commit, rootState }, params) {
		commit('setLoading', true)
		const theme = this.$config.THEME
		await this.$axios
			.get(`/cms/lnd/component-contents?currency=${rootState.settings.defaultCurrency}&theme_code=${theme}`, retry)
			.then((response) => {
				// commit('cms', response?.data?.components) // old format
				commit('cms', response?.data)
				const cms = response?.data
				const data = cms?.find((object) => object.component_code === 'JackpotContent');
				if (data && data.time_interval && data.is_visible) {
					// setJackpotInterval(commit, this, 1)
					setJackpotUnlimitedTimeInterval(commit, this, 1) // 'this' is global variable (Can access global vuex store, cookie, axios, moment...)
				} else {
					commit('setJackpotVal', 0)
				}
				commit('setLoading', false)
			})
			.catch(() => false)
	},
	async getFavicon({ commit, state }) {
		// Check if the favicon is already existing, then just return the favicon
		if (state.favicon) {
			return state.favicon
		}
		const theme = this.$config.THEME;
		// Since This API woks only in the Server Side, we don't need to call it by ingress
		let path = `/cms/lnd/favicon?theme_code=${theme}`
		if (this.$config.PRODUCTION && process.server) {
			path = this.$config.CMS_SERVER_URL + path
		}

		const response = await this.$axios
			.get(path, retry)
			.then((response) => {
				if (response.data?.image_path) {
					commit('setFavicon', response.data.image_path)
					return response.data?.image_path
				}
			})
			.catch((_) => {
				return false
			})

		return response;
	},
}

// Mutations
export const mutations = {
	cms(state, data) {
		state.cms = data
	},
	setLoading: (state, type) => {
		state.loading = type
	},
	setJackpotVal: (state, val) => {
		state.jackpotVal = val
	},
	setFavicon(state, data) {
		state.favicon = data
	}
}

// Getters
export const getters = {
	getCMS: (state) => (input) => {
		const _res = state?.cms?.length > 0
			? state?.cms?.find((object) => object.component_code === input)
			: ''
		return _res;
	},
	jackpotVal: state => state.jackpotVal,
	faviconIcon: (state) => {
		const logo = state?.cms?.find((object) => object.component_code === 'Favicon')
		return logo || {};
	},
	headerLogo: (state) => {
		const logo = state?.cms?.find((object) => object.component_code === 'HeaderLogo')
		return logo || {};
	},
	desktopBackground: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'DesktopBackground')
		return data || {};
	},
	desktopSlider: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'SliderContentDesktop')
		return data || {};
	},
	mobileBackground: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'MobileBackground')
		return data || {};
	},
	mobileSlider: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'SliderContentMobile')
		return data || {};
	},
	inviteAndEarnBanner: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'InviteAndEarnBanner')
		return data || {};
	},
	rightFloatingSupportIcon: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'FloatingIcon1')
		return data || {};
	},
	rightFloatingMessageIcon: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'FloatingIcon2')
		return data || {};
	},
	holdBalanceInstruction: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'OnHoldBalanceInstructionalContent')
		return data || {};
	},
	depositInstruction: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'DepositInstructionContent')
		return data || {};
	},
	introductionVideoContents: (state) => {
		// Find video content component_code = VideoContent1 - VideoContent2
		const data = state?.cms?.filter((object) => object?.component_code?.startsWith('VideoContent'))
		return data.sort((a, b) => a?.component_code?.localeCompare(b?.component_code)) || [];
	},
	introductionVideoContent1: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'VideoContent1')
		return data || {};
	},
	introductionVideoContent2: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'VideoContent2')
		return data || {};
	},
	lotteryWinner: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'DesktopLotteryWinners')
		return data || {};
	},
	topDepositRanking: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'TopDepositRanking')
		return data || {};
	},
	featuredContent1: (state) => (theme) => {
		const searchingKeys = theme === globalVar.themes.theme3.value ? 'FeaturedContent1Desktop' : 'FeaturedContent1'
		const data = state?.cms?.find((object) => object.component_code === searchingKeys)
		return data || {};
	},
	featuredContent2: (state) => (theme) => {
		const searchingKeys = theme === globalVar.themes.theme3.value ? 'FeaturedContent2Desktop' : 'FeaturedContent2'
		const data = state?.cms?.find((object) => object.component_code === searchingKeys)
		return data || {};
	},
	featuredContent3: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'FeaturedContent3')
		return data || {};
	},
	jackpot: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'JackpotContent')
		return data || {};
	},
	promotion: (state) => (theme) => {
		const searchingKeys = theme === globalVar.themes.theme3.value ? 'PromotionContentDesktop' : 'PromotionContent'
		const data = state?.cms?.find((object) => object.component_code === searchingKeys)
		return data || {};
	},

	recentWinner: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'RecentWinners')
		return data || {};
	},
	topWinner: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'TopWinners')
		return data || {};
	},
	unluckyWinner: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'UnluckyWinners')
		return data || {};
	},
	footerLogo: (state) => {
		const logo = state?.cms?.find((object) => object.component_code === 'FooterLogo')
		return logo || {};
	},
	footerPartnerIcon: (state) => {
		const logo = state?.cms?.find((object) => object.component_code === 'PartnerIcon')
		return logo || {};
	},
	detailArticles: (state) => {
		// Find article content component_code = ArticleContent1 -ArticleContent3
		const data = []
		const article1 = filterAndSortArticle(state?.cms, 'ArticleContent1')
		const article2 = filterAndSortArticle(state?.cms, 'ArticleContent2')
		const article3 = filterAndSortArticle(state?.cms, 'ArticleContent3')
		const termAndCondition = state?.cms?.find((object) => object.component_code === 'TermsAndConditions')
		if (article1 && article1?.is_visible) data.push(article1)
		if (article2 && article2?.is_visible) data.push(article2)
		if (article3 && article3?.is_visible) data.push(article3)
		if (termAndCondition) data.push(termAndCondition)
		return data;
	},
	detailArticlesTheme3: (state) => {
		// Find article content component_code = ArticleContent1 -ArticleContent3
		const data = []
		const article1 = filterAndSortArticle(state?.cms, 'ArticleContent1')
		const article2 = filterAndSortArticle(state?.cms, 'ArticleContent2')
		const termAndCondition = state?.cms?.find((object) => object.component_code === 'TermsAndConditions')
		if (article1 && article1?.is_visible) data.push(article1)
		if (article2 && article2?.is_visible) data.push(article2)
		if (termAndCondition && termAndCondition?.is_visible) data.push(termAndCondition)
		return data;
	},
	footerArticle1: (state) => {
		const data = filterAndSortArticle(state?.cms, 'ArticleContent1')
		return data
	},
	footerArticle2: (state) => {
		const data = filterAndSortArticle(state?.cms, 'ArticleContent2')
		return data
	},
	footerArticle3: (state) => {
		const data = filterAndSortArticle(state?.cms, 'ArticleContent3')
		return data
	},
	footerArticle4: (state) => {
		const data = filterAndSortArticle(state?.cms, 'ArticleContent4')
		return data
	},
	footerGamingInfo: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'GamingInformation')
		return data || {};
	},
	footerTermAndCondition: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'TermsAndConditions')
		return data || {};
	},
	splashScreen: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'DesktopSplashScreen')
		return data || {};
	},
	registrationImage: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'RegistrationImage')
		return data || {};
	},
	bannerDeposit: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'BannerDeposit')
		return data || {};
	},
	announcement: (state) => {
		const data = state?.cms?.find((object) => object.component_code === 'Announcement')
		return data || {};
	},
	getSequenceAbleComponent: (state) => (platform) => {
		const data = state?.cms?.filter(el => {
			const isSequence = el.is_sequenceable
			const isMatchPlatform = platform ? el.platform.includes(platform) : true
			return isSequence && isMatchPlatform
		})
		const response = data || []
		const sortKey = platform === 'desktop' ? 'sequence_desktop' : 'sequence_mobile'
		return response.sort((a, b) => a[sortKey] - b[sortKey]).map(el => {
			return {
				...el,
				sequence: el[sortKey]
			}
		})
	}
}
function getRandomTimeoutNumber() {
	const randomDecimal = Math.random();
	const randomNumber = Math.floor(randomDecimal * (3000 - 1000 + 1)) + 1000;
	return randomNumber
}
function getJVal(hourNotIncludeCurrentDay, maxAmount, minAmount, timeSecondInterval, timeInterval, extraTime = null) {
	/* ------------- Get time data of current day ------------- */
	let currentDateTime = null;
	if (!extraTime) {
		currentDateTime = new Date();
	} else {
		currentDateTime = new Date(new Date().getTime() + extraTime);
	}

	const currentHours = currentDateTime.getHours();
	const currentMinutes = currentDateTime.getMinutes();
	/* ------------- Get time data of current day ------------- */

	const unFullRound = (hourNotIncludeCurrentDay + currentHours) % timeInterval // Calculate the hours of unfull round
	/*
		1. if unFullRound = 0 that mean just finish a round and continue to new round
		2. if unFullRound > 0 the current round is going on and the value of unFullRound is the hours of current round that is passed
	*/

	const second = ((unFullRound * 60 + currentMinutes) * 60 - 1) + currentDateTime.getSeconds() // calculate the passed seconds
	const distanceVal = maxAmount - minAmount	// calculate distance value between min and max
	const valPerSecond = Math.floor(distanceVal / timeSecondInterval) // Calculate value per second

	const jVal = second <= 6 ? minAmount : second >= (timeSecondInterval - 6) ? maxAmount : minAmount + (valPerSecond * second) // Calculate jacpot display value
	/*
		1. second less than 6s ( second <= 6 ) display min jackpot value from setting
		2. second less than max second of current round about 6s ( second >= (timeSecondInterval - 6) ) display max jackpot value from setting
		3. Another second just calculate to find out the suitable random jackpot value then display to client
		{ *note: if not set less or greater than like condition 1 and 2 sometime program would skip display min and max jackpot value }
	 */
	return jVal
}
function setJackpotUnlimitedTimeInterval(commit, global, randomTime) { // Unlimited time interval setting value
	setTimeout(async () => {

		/* ------------- Declare jackpot data ------------- */
		const timeInterval = global.getters['cms/jackpot'].time_interval;
		const minAmount = global.getters['cms/jackpot'].min_jackpot_amount;
		const maxAmount = global.getters['cms/jackpot'].max_jackpot_amount;
		const intervalStartDate = global.getters['cms/jackpot'].updated_at;
		/* ------------- Declare jackpot data ------------- */

		/* ------------- Calculate all duration days from start setting jactpot until current day ------------- */
		const $moment = global.$moment
		const duration = $moment.duration(
			$moment($moment().local().format('YYYY-MM-DD')).local().diff(
				intervalStartDate ?
					$moment(intervalStartDate).local().format('YYYY-MM-DD') :
					$moment().local().format('YYYY-MM-DD')
			)
		)
		const durationDays = duration.asDays()
		/* ------------- Calculate all duration days from start setting jactpot until current day ------------- */

		const hourNotIncludeCurrentDay = durationDays * 24; // Calculate all hours of the duration days that is not including current day hours

		const timeSecondInterval = (timeInterval * 60) * 60 - 1 // Calculate all seconds of the jackpot time interval


		const jVal = getJVal(hourNotIncludeCurrentDay, maxAmount, minAmount, timeSecondInterval, timeInterval, null)

		const randomSecond = Math.floor(getRandomTimeoutNumber()) // find randome second between 1s - 3s
		await asyncOperation('i', randomSecond)
		setJackpotUnlimitedTimeInterval(commit, global, randomSecond * 2) // Set next jackpot random

		const nextJVal = getJVal(hourNotIncludeCurrentDay, maxAmount, minAmount, timeSecondInterval, timeInterval, randomSecond * 2)
		const diffJVal = nextJVal - jVal

		const internalTimeForEach = randomSecond / diffJVal
		for (let i = 0; i < diffJVal; i++) {
			const currentJackpotVal = global.state.cms.jackpotVal;
			if (jVal + i > currentJackpotVal) {
				commit('setJackpotVal', jVal + i) // Set jackpot value to store
			}
			await asyncOperation(i, internalTimeForEach);

		}
	}, randomTime)
}
function asyncOperation(item, timeoutTime) {
	return new Promise(resolve => {
		setTimeout(() => {
			resolve(item);
		}, timeoutTime);
	});
}