// State
export const state = () => ({
	loading: false,
	menu: [],
	currency: [],
	languages: [],
	language: 'en-US',
	countries: [],
	exchangeRate: [],
	slider: [],
	isLogin: false,
	locale: 'th-TH',
	defaultCurrency: null,
	defaultCountry: null,
	displayPopup: true,
	downloadInterval: false,
	supportCountryAndLanguage: [],
	defaultSupportedLanguage: [],
	changeLanguageLoading: false,
	globalFlag: "GO",
	operationSetting: {},
	logo: {},
	cryptoWalletAddressList: [],
	// cancel token
	cancelToken: {},
})

// Actions
export const actions = {
	// Currency
	async currency({ commit }, params) {
		try {
			await this.$axios.get(`/currency`, { params }).then((response) => {
				commit('setCurrency', response.data)
			})
		} catch (error) {
			return false
		}
	},
	// Get countries - WING CODE
	async countries({ commit }) {
		try {
			await this.$axios.get(`/country`).then((response) => {
				commit('setCountries', response.data)
			})
		} catch (error) {
			return false
		}
	},
	toggleIsLogin({ commit }, status) {
		commit('setIsLogin', status)
	},
	locale({ commit }, lang) {
		commit('locale', lang)
	},
	async getDefaultCurrency({ commit, dispatch }, params) {

		/**
			  How to the default language is set; Sort by the highest to lowest priority.
			1. Highest priority, check if the browser cookie has set the language. Use that language to fetch language strings.
			2. Check supported languages, if only one is available, use that one as the default language.
			3. Get the browser language by navigator.language. Check if the language is matched by some supported languages, use that language as the default.
			4. Find the the language which is active and is default. Use that one as the default language.
			5. If all the above conditions are false, use the first supported languages as the default language.
		 */
		await this.$axios('currency/default', params)
			.then(async (data) => {
				let languageStringLanguage = null;
				const { currency: defaultCurrency, country_code: defaultCountryCode, country: defaultCountry, domaintype: domainType } = data?.headers

				const currency = this.$cookie.get('currency')
				const country = this.$cookie.get('country')
				const countryCode = this.$cookie.get("country_code")
				const localeInTheCookie = this.$cookie.get('wl_locale')
				/**
				 * Cookies explanation
				 * 1. global_reg is on register screen for default phone number
				 * 2. country is country
				 * 3. country_code is for each country flag
				 */

				// global registration
				this.$cookie.set("global_reg", defaultCountry)
				globalRegister(this.$cookie, commit, domainType, currency, defaultCurrency, country, defaultCountry, countryCode, defaultCountryCode)

				let supportedLanguages = data?.data?.supported_languages

				// Get the supported country from supported languages
				const supportedCountryByToken = await dispatch('supportCountryAndLanguage')

				const languages = findSupportLanguage(supportedCountryByToken, currency, countryCode)

				if (languages) supportedLanguages = languages

				commit("defaultSupportedLanguage", supportedLanguages)

				// Use the locale stored in the cookie, if it exists, as the default language.
				// This is necessary during the initialization of website settings, as the Locale state
				// is set later in the process. Relying solely on cookies ensures that the locale has
				// been preserved across sessions.
				// If the locale in the cookie is active, apply it. Otherwise, fallback to the default.
				if (localeInTheCookie) {
					// Validate if the cookie locale is still active; if so, use it, else fallback to default.
					const locale = activeLocale(commit, dispatch, supportedLanguages, localeInTheCookie)

					if (locale) languageStringLanguage = locale;
				}

				// After all the LOGIC above, if system Locale is still not set, then find the default one from supportedLanguages list
				if (!languageStringLanguage && supportedLanguages.length > 0) {
					// If the support language has only One Language, just use that one as default
					const defaultLanguage = defaultSupportLanguage(dispatch, supportedLanguages)

					if (defaultLanguage) languageStringLanguage = defaultLanguage
				}

				if (languageStringLanguage) {
					dispatch('themes/language-string/fetchLanguageStrings', languageStringLanguage, { root: true })
				}
			})
			.catch(_ => { })
	},
	async supportCountryAndLanguage({ commit, state }, params = {}) {
		if (state.supportCountryAndLanguage.length !== 0 && params?.fetch !== true) return state.supportCountryAndLanguage
		const response = await this.$axios
			.get('/country/supported-language')
			.then((response) => {
				if (response.data?.length === 1) {
					this.$cookie.set("country_code", response.data[0]?.country_code)
					commit("setGlobalFlag", response.data[0]?.country_code)
				}
				commit('supportCountryAndLanguage', response.data)
				return response.data
			})
			.catch(() => [])
		return response;
	},
	async operationSetting({ commit, state, rootState }, currency) {
		await this.$axios
			.get(`/website-setting/operation-setting/${currency || state.defaultCurrency}`)
			.then((response) => {
				const data = response.data;
				commit("operationSetting", response.data)
				//  execute only inter wl
				if (rootState.themes.settings.isInternational) {
					commit('themes/deposit/depositFlow', {
						deposit_type: data.deposit_type,
						is_bank_transfer_enabled: data?.payment_type_settings?.bank_transfer || false,
						is_third_party_payment_enabled: data?.payment_type_settings?.third_party || false,
						is_crypto_payment_enabled: data?.payment_type_settings?.crypto_currency || false,
						reg_flow: data.reg_flow
					}, { root: true })
				}
			})
			.catch(() => {
				return false
			})

	},
	async getCryptoWalletAddressList({ commit }, params) {
		await this.$axios('/currency/crypto', { params })
			.then((data) => {
				commit('setCryptoWalletAddressList', data.data)
			})
			.catch(_ => { })
	},
}

// Mutations
export const mutations = {
	loading: (state, v) => {
		state.loading = v
	},
	setCurrency: (state, v) => {
		state.currency = v
	},
	setCountries: (state, v) => {
		state.countries = v
	},
	setMenu: (state, v) => {
		const promotion = {
			title: {
				'en-US': 'Promotion',
				'lo-LA': 'ໂປຮໂມຊັນ',
				'th-TH': 'โปรโมชั่น',
			},
			to: '/promotion',
			game_type_code: 'OT',
		};
		state.menu = [...v, promotion]
		// state.menu = v.concat(state.menu)
	},
	setExchangeRate: (state, v) => {
		state.exchangeRate = v
	},
	setIsLogin: (state, v) => {
		state.isLogin = v
	},
	locale: (state, v) => {
		state.locale = v
	},
	setCancelToken: (state, data) => {
		const oldData = { ...state.cancelToken }
		state.cancelToken = {
			...oldData, ...data
		}
	},
	setDefaultCurrency: (state, data) => {
		state.defaultCurrency = data
	},
	setDefaultCountry: (state, data) => {
		state.defaultCountry = data
	},
	setGlobalFlag: (state, data) => {
		state.globalFlag = data
	},
	setDisplayPopup: (state, data) => {
		state.displayPopup = data
	},
	setDownloadInterval: (state, data) => {
		state.downloadInterval = data
	},
	supportCountryAndLanguage: (state, data) => {
		state.supportCountryAndLanguage = data
	},
	defaultSupportedLanguage: (state, data) => {
		state.defaultSupportedLanguage = data
	},
	changeLanguageLoading: (state, data) => {
		state.changeLanguageLoading = data;
	},
	operationSetting: (state, data) => {
		state.operationSetting = data;
	},
	setCryptoWalletAddressList: (state, data) => {
		state.cryptoWalletAddressList = data;
	},
}

// Getters
export const getters = {
	currency: (state) => {
		return state.currency
	},
	cryptoCurrencies: (state) => {
		return state?.currency?.filter((el) => el.type === 2)
	},
	currencyIsExchange: (state) => {
		return state.currency.filter((o) => o.is_exchange)
	},
	countries: (state) => {
		return state.countries
	},
	floatingIcons: (state) => {
		return state.floatingIcons
	},
	language: (state) => {
		return state.language
	},
	languages: (state) => {
		return state.languages.filter((o) => o.is_active)
	},
	locale: (state) => {
		return state.locale
	},
	exchangeRate: (state) => (from, to) => {
		let filter = {
			rate: 0,
		}
		filter = state.exchangeRate.find(
			(o) => o.from_currency === from && o.to_currency === to
		)
		return filter
	},
}

const globalRegister = (cookie, commit, domainType, currency, defaultCurrency, country, defaultCountry, countryCode, defaultCountryCode) => {
	if (domainType) {
		commit('base-settings/setConfiguration', { domain_type: domainType }, { root: true })
	}
	// If currency is not exist, than set currency
	if (!currency) {
		cookie.set('currency', defaultCurrency)
		commit('setDefaultCurrency', defaultCurrency)
	} else {
		commit('setDefaultCurrency', currency)
	}

	if (!country) {
		cookie.set('country', defaultCountry)
		commit("setDefaultCountry", defaultCountry)
	}

	if (!countryCode) {
		cookie.set('country_code', defaultCountryCode)
		commit("setGlobalFlag", defaultCountryCode)
	} else {
		commit("setGlobalFlag", countryCode)
	}
}

const findSupportLanguage = (supportedCountryByToken, currency, countryCode) => {
	if (supportedCountryByToken?.length && currency) {
		const findMatchSupportedCountryByCurrency = supportedCountryByToken.find((e) => findMatchSupportedCountryByCurrencyFunc(e, currency, countryCode))

		if (findMatchSupportedCountryByCurrency) {
			return findMatchSupportedCountryByCurrency.supported_languages
		}
	}
}

const findMatchSupportedCountryByCurrencyFunc = (el, currency, countryCode) => {
	if (countryCode) {
		if (el.currency_code === currency && el.country_code === countryCode) {
			return true;
		}
		return false;
	} else if (el.currency_code === currency) {
		return true;
	}

	return false;
}

const activeLocale = (commit, dispatch, supportedLanguages, localeInTheCookie) => {
	const isCookieLocaleActive = supportedLanguages.find(locale => locale.language === localeInTheCookie && locale.is_active === true)
	if (isCookieLocaleActive?.is_active) {
		commit('locale', localeInTheCookie)
		dispatch('base-settings/updateLocaleAndSetCookie', localeInTheCookie, { root: true })

		return localeInTheCookie
	}
}

const defaultSupportLanguage = (dispatch, supportedLanguages) => {
	let matchedLocale = null;
	if (supportedLanguages.length === 1) {
		matchedLocale = supportedLanguages[0].language
	} else {
		// Check if the browser language is matched to one of the supported languages
		const browserLanguage = navigator?.language
		const browserPrefixLanguageTag = browserLanguage?.substring(0, 2)

		// Find the language which starting with the same browser Prefix Language Tag
		let matchBrowserLanguage = null;
		if (browserPrefixLanguageTag) {
			matchBrowserLanguage = findMatchBrowserLanguage(supportedLanguages, browserPrefixLanguageTag)
		}

		// Find the browser Prefix Language is match, then use only browser language
		if (matchBrowserLanguage) {
			matchedLocale = matchBrowserLanguage.language
		} else {
			// If browser doesn't supported navigator.language, then use the default and active one from BO
			const findIsActiveLanguage = supportedLanguages.find(el => el.is_active && el.is_default)
			if (findIsActiveLanguage) {
				matchedLocale = findIsActiveLanguage.language
			} else {
				matchedLocale = supportedLanguages[0].language
			}
		}
	}
	dispatch('base-settings/updateLocaleAndSetCookie', matchedLocale, { root: true })

	return matchedLocale
}

const findMatchBrowserLanguage = (supportedLanguages, browserPrefixLanguageTag) => {
	return supportedLanguages.find(({ language, is_active: isActive }) => isActive && language.includes(browserPrefixLanguageTag));
}
